import React, {
  useContext,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from 'react';
import { navigate } from 'gatsby';
import MainHeader from '../../components/main-header/main-header';
import PageHeader from '../../components/page-header/page-header';
import Button from '../../components/button/button';
import MainFooter from '../../components/main-footer/main-footer';
import Dropdown from '../../components/dropdown/dropdown';
import { DataContext } from '../../data/DataProvider';
import './index.scss';
import DefaultLayout from '../../components/layouts/default-layout';
import {
  cancelEdit,
  changeValorParcela,
  isEditValid,
} from '../../helpers/editQuotation';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const yesOrNot = [
  {
    label: 'Sim',
    value: true,
  },
  {
    label: 'Não',
    value: false,
  },
];

const sinistralidade = [
  {
    label: 'Nenhum',
    value: 0,
  },
  {
    label: '1 sinistro',
    value: 1,
  },
  {
    label: '2 sinistros',
    value: 2,
  },
  {
    label: '3 ou mais sinistros',
    value: 3,
  },
];

const block = 'page-sinistralidade';
const Page = ({ location }) => {
  const {
    quotation,
    setQuotation,
    originalQuotation,
    setModal,
    setOriginalQuotation,
    responseMotor,
  } = useContext(DataContext);
  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    if (!quotation || !Object.keys(quotation).length) navigate('/');
    if (quotation && !quotation?.sistemaProtecional)
      return navigate('/sistemas-protecionais');
  }, [quotation, quotation?.sistemaProtecional]);

  const cancel = () => {
    cancelEdit(setQuotation, originalQuotation);
  };
  const save = () => {
    return changeValorParcela(
      quotation,
      responseMotor.response,
      setModal,
      setDisableSave,
      setOriginalQuotation
    );
  };

  const sinistrosCincoAnosChangeHandler = useCallback(
    (selectedItem) => {
      trackCustomEvent({
        category: 'Sinistro 5 anos',
        action: 'Click',
        label: selectedItem.value,
      });
      setQuotation({
        ...quotation,
        sinistralidade: {
          ...quotation.sinistralidade,
          sinistrosUltimosCincoAnos: selectedItem.value,
        },
        isProposal: false,
      });
    },
    [quotation, setQuotation]
  );

  const sinistrosDozeMesesChangeHandler = useCallback(
    (selectedItem) => {
      trackCustomEvent({
        category: 'Sinistro 12 meses',
        action: 'Click',
        label: selectedItem.value,
      });
      setQuotation({
        ...quotation,
        sinistralidade: {
          ...quotation.sinistralidade,
          sinistrosUltimosDozeMeses: selectedItem.value,
        },
        isProposal: false,
      });
    },
    [quotation, setQuotation]
  );

  const isValid = useMemo(() => {
    if (
      quotation?.sinistralidade?.sinistrosUltimosCincoAnos === undefined ||
      quotation?.sinistralidade?.sinistrosUltimosCincoAnos >= 3 ||
      !quotation?.sinistralidade?.hasOwnProperty('sinistrosUltimosDozeMeses') ||
      quotation?.sinistralidade?.sinistrosUltimosDozeMeses
    ) {
      return false;
    }

    return true;
  }, [quotation]);

  return (
    <DefaultLayout currentPagePath={location.pathname}>
      <main className={block}>
        <MainHeader />
        <PageHeader
          backButtonHandler={() => navigate('/sistemas-protecionais')}
          editMode={!!quotation?.propostaId}
          title="Sinistralidade"
          description={
            <span>
              A sinistralidade da empresa influência na aceitação da proposta.
              <br />
              Você será informado caso haja alguma restrição.
            </span>
          }
          breadCrumbs={[
            {
              label: 'Início',
              path: '/',
            },
            {
              label: 'CNPJ',
              path: '/cnpj',
            },
            {
              label: 'Dados da empresa',
              path: '/dados-da-empresa',
            },
            {
              label: 'Informações do seguro',
              path: '/informacoes-do-seguro',
            },
            {
              label: 'Avaliação de risco',
              path: '/avaliacao-de-risco',
            },
            {
              label: 'Sistemas protecionais',
              path: '/sistemas-protecionais',
            },
            {
              label: 'Sinistralidade',
              path: '/sinistralidade',
            },
          ]}
          fixed
          hide={quotation?.personalizado}
        />
        <div className={`container--large ${block}--fixed`}>
          <div className={`${block}--row`}>
            <div className={`${block}--item`}>
              <span className={`${block}__label`}>
                Houve algum sinistro maior do que R$ 50 mil nos últimos 12 meses
                ?
              </span>
              <Dropdown
                className={`${block}__dropdown`}
                placeholder="Selecione"
                items={yesOrNot}
                changeHandler={sinistrosDozeMesesChangeHandler}
                selectedValue={
                  quotation?.sinistralidade?.sinistrosUltimosDozeMeses
                }
              />
            </div>
          </div>
          <div className={`${block}--row`}>
            <div className={`${block}--item`}>
              <span className={`${block}__label`}>
                Informe a quantidade de sinistros ocorridos na empresa nos
                últimos 5 anos
              </span>
              <Dropdown
                className={`${block}__dropdown`}
                placeholder="Selecione"
                items={sinistralidade}
                changeHandler={sinistrosCincoAnosChangeHandler}
                selectedValue={
                  quotation?.sinistralidade?.sinistrosUltimosCincoAnos
                }
              />
            </div>
          </div>
          <div>
            {isEditValid(quotation) && (
              <div className="action_buttons">
                <Button
                  className="button_cta"
                  type="button"
                  theme={'secondary'}
                  onClick={cancel}
                  disabled={disableSave}
                >
                  Cancelar edição
                </Button>
                <Button
                  className={`button_cta button--margin-left`}
                  theme={'tertiary'}
                  onClick={save}
                  disabled={!isValid || disableSave}
                >
                  Salvar edição
                </Button>
              </div>
            )}
            {!quotation?.propostaId && (
              <Button
                className="button_cta"
                onClick={() => navigate('/exposicao-politica')}
                disabled={!isValid}
              >
                Avançar
              </Button>
            )}
          </div>
        </div>
        <MainFooter />
      </main>
    </DefaultLayout>
  );
};

export default Page;
